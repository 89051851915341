import React from "react"
import ReactDOM from "react-dom"
import Root from "./components/Root"
import "./index.css"

import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"

import PrimeReact from "primereact/api"
PrimeReact.ripple = true
PrimeReact.inputStyle = "filled"
PrimeReact.zIndex = {
  modal: 10000, // dialog, sidebar
  overlay: 10000, // dropdown, overlaypanel
  menu: 1000, // overlay menus
  tooltip: 1100, // tooltip
  toast: 1200, // toast
}

ReactDOM.render(<Root />, document.getElementById("root"))
