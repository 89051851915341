export const prefixes = {
  PROJECTS: "PROJECTS/",
  BLOG_POSTS: "BLOG_POSTS/",
  SOCIALS: "SOCIALS/",
}

export const actionTypes = {
  FETCH: "FETCH:",
}

export const status = {
  START: "START",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
}
