import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"


import "./project.css"

const Project = ({ project, onClick }) => {
  const className = project.highlight ? "highlight-card" : "normal-card"

  return (
    <div
      className={"col-12 lg:col-6 xl:col-4 project-card clickable text-unselectable " + className}
      onClick={ev => onClick(project.id)}
    >
      <LazyLoadImage
        className="project-image"
        effect="blur"
        width="100%"
        height="25em"
        alt={project.name}
        src={project.imgSmall}
      />
      <div className="info-bar">
        <div>{project.name}</div>
        
      </div>
    </div>
  )
}

export default Project
