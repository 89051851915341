import React from "react"
import { LazyLoadComponent } from "react-lazy-load-image-component"

import { TransitionGroup, CSSTransition } from "react-transition-group"

import Project from "./Project"

import "./projectsContainer.css"

const ProjectsContainer = ({ projects, activeLabels, projectClick }) => {
  projects = projects.sort((a, b) => a.highlight < b.highlight)

  const filterActiveProjects = project => {
    if (activeLabels.length === 0) return true

    return isProjectActive(project, activeLabels)
  }

  const isProjectActive = (project, activeLabels) => {
    for (let i = 0; i < project.labels.length; i++) {
      const label = project.labels[i]
      if (activeLabels.indexOf(label) > -1) return true
    }

    return false
  }

  return (
    <TransitionGroup className="grid">
      {projects.filter(filterActiveProjects).map((project, index) => (
        <CSSTransition
          key={index}
          classNames="project-fade"
          timeout={{ enter: 300, exit: 300 }}
        >
          <LazyLoadComponent>
            <Project
              key={project.name}
              project={project}
              onClick={projectClick}
            />
          </LazyLoadComponent>
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default ProjectsContainer
