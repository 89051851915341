// eslint-disable-next-line
export default {
  fetchProjects: () =>
    new Promise((resolve, reject) =>
      resolve([
        // {
        //   id: "Toyota Crown",
        //   name: "Toyota Crown",
        //   subtitle: "Toyota Crown visualization",
        //   description: "",
        //   links: [
        //     {
        //       url: "https://toyotacrown.groove-tech.com/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [],
        //   labels: ["React", "Three.js", "R3F"],
        //   imgSmall: "images/toyoda-crown.jpg",
        //   img: "images/toyoda-crown.jpg",
        //   highlight: true,
        // },
        {
          id: "IBM Power10",
          name: "IBM Power10",
          subtitle: "Interactive 3D Product Showcase",
          description: "Built the elegant website with React and R3F.",
          links: [
            {
              url: "https://ibm-power10-experience.web.app/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [
            {
              url: "https://vimeo.com/619221523",
              name: "Watch a video",
              icon: "fas fa-video",
            },
          ],
          labels: ["R3F", "React", "Three.js", "Framer", "React-spring"],
          imgSmall:
            "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/73ae7100-e84a-484e-9e70-b6658f80e516.gif?ClientID=vimeo-core-prod&Date=1633028891&Signature=b7f6019e86cc558fa504464bcfc0c1acd522b323",
          img: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/73ae7100-e84a-484e-9e70-b6658f80e516.gif?ClientID=vimeo-core-prod&Date=1633028891&Signature=b7f6019e86cc558fa504464bcfc0c1acd522b323",
          highlight: true,
        },
        {
          id: "Toyota factory",
          name: "Toyota factory",
          subtitle: "Toyota factory visualization",
          description: "",
          links: [
            {
              url: "http://dev2.trigen.groove-tech.com/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Three.js"],
          imgSmall: "images/toyota-factory.jpg",
          img: "images/toyota-factory.jpg",
          highlight: true,
        },
        {
          id: "ECFC",
          name: "ECFC",
          subtitle: "Data Visualization",
          description: "Wildlife Seizure Dashboard",
          links: [
            {
              url: "https://wildlifedashboard.c4ads.org/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: [
            "React",
            "D3.js",
            "Primefaces",
            "Rechart",
            "Node.js",
            "DataViz",
            "React Query",
          ],
          imgSmall: "images/ecfc.jpg",
          img: "images/ecfc.jpg",
          highlight: true,
        },
        {
          id: "Sanctions Explorer",
          name: "Sanctions Explorer",
          subtitle: "Explore 24 years of OFAC Sanctions Data",
          description:
            "7,100+ entries across the SDN, SSI, FSE, and more. Updated < 5 minutes ago.",
          links: [
            {
              url: "https://sanctionsexplorer.org/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Nivo", "Python", "Node.js", "ExpressJS"],
          imgSmall: "images/sanctionexplorer.jpg",
          img: "images/sanctionexplorer.jpg",
          highlight: true,
        },
        {
          id: "Zoned out",
          name: "Zoned out",
          subtitle: "Data Visualization",
          description:
            "An Interactive Impact Evaluation of Mekong Economic Development Zones",
          links: [
            {
              url: "https://mekong.c4ads.org/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: [
            "React",
            "Material UI",
            "Deck.gl",
            "Cytoscape",
            "DataViz",
            "Mapbox",
            "Node.js",
          ],
          imgSmall: "images/zonedout.jpg",
          img: "images/zonedout.jpg",
          highlight: true,
        },
        {
          id: "Ocelli",
          name: "Ocelli",
          subtitle: "Data Visualization",
          description:
            "An interactive geospatial dataset that documents open source evidence of the systematic displacement and genocide of the Rohingya ethnic minority in Myanmar's Rakhine state",
          links: [
            {
              url: "https://ocelli.c4ads.org/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Deck.gl", "Mapbox", "Node.js", "DataViz", "SCSS"],
          imgSmall: "images/ocelli.jpg",
          img: "images/ocelli.jpg",
          highlight: true,
        },
        {
          id: "Fuel Shipment Map",
          name: "Fuel Shipment Map",
          subtitle: "DataViz - Interactive Fuel Shipment Map",
          description:
            "The interactive map shows shipment route with Yemen from other countires. The data is time series data, and added the playable timeline.",
          links: [
            {
              url: "https://yemen-fuel-map.web.app/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["Deck.gl", "Map", "Mapbox", "React", "D3.js", "DataViz"],
          imgSmall: "images/yemenfuelmap.jpg",
          img: "images/yemenfuelmap.jpg",
          highlight: true,
        },
        {
          id: "Trade Map",
          name: "Trade Map",
          subtitle: "DataViz - Interactive Trade Map",
          description:
            "The interactive map shows trade route with Libya from other countires. The data is time series data, and added the playable timeline.",
          links: [
            {
              url: "https://c4ads-libya-trade-map.web.app/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["Deck.gl", "Map", "Mapbox", "React", "D3.js", "DataViz"],
          imgSmall:
            "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/d1/1160340/829e0c24bdc69a076b94e91d34d0c7fe-original.JPG",
          img: "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/d1/1160340/829e0c24bdc69a076b94e91d34d0c7fe-original.JPG",
          highlight: true,
        },
        {
          id: "Route Tool",
          name: "Route Tool",
          subtitle: "DataViz - Interactive Route Map",
          description:
            "This is to take some flight route data and create a single page visualization that renders the flight route selected with some summary statistics about wildlife trafficking along that route, and any other route that was also used in conjunction with that route to traffic wildlife. The user will have two filters on the left side of the screen and will select two cities for origin and destination. Then all instances of wildlife trafficking will list on the right side of the screen and the user can see specifics related to trafficking along that particular route.",
          links: [
            {
              url: "http://d25wluwm378t31.cloudfront.net/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["Deck.gl", "Map", "Mapbox", "React", "D3.js", "DataViz"],
          imgSmall:
            "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/ea/1160344/d57b243c0f7524a05280eedc953a3ad3-original.JPG",
          img: "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/ea/1160344/d57b243c0f7524a05280eedc953a3ad3-original.JPG",
          highlight: true,
        },
        {
          id: "Country Profile",
          name: "Country Profile",
          subtitle: "DataViz - Interactive Globe",
          description:
            "The interactive Country Profile Map provides an overview of the wildlife seizures made in airports around the world by country.",
          links: [
            {
              url: "https://d327zvdnkttgtq.cloudfront.net/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["Amcharts", "React", "D3.js", "DataViz"],
          imgSmall:
            "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/f5/1160355/62f695c23a8138f97f8b3d3fa03c2769-original.JPG",
          img: "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/f5/1160355/62f695c23a8138f97f8b3d3fa03c2769-original.JPG",
          highlight: true,
        },
        {
          id: "ConflictID",
          name: "ConflictID",
          subtitle: "Data visualization",
          description:
            "ConflictID is a project to collect, identify, and analyze images of weapons in conflict, starting with South Sudan. By curating this publicly available imagery, C4ADS hopes to empower data-driven decision making in response to conflict.",
          links: [
            {
              url: "https://conflict.id/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["Vue.js", "D3.js", "DataViz"],
          imgSmall: "images/conflict.jpg",
          img: "images/conflict.jpg",
          highlight: true,
        },
        {
          id: "Routes Dashboard",
          name: "Routes Dashboard",
          subtitle: "Interactive Wildlife Seizure Dashboard",
          description:
            "The ROUTES Dashboard is an interactive website providing graphics on wildlife trafficking through airports between 2009 and 2020. The Dashboard’s graphics are derived from open source wildlife seizure data collected by C4ADS under the ROUTES Partnership.",
          links: [
            {
              url: "http://www.routesdashboard.org/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["Vue.js", "D3.js", "DataViz", "Amcharts"],
          imgSmall: "images/routedashboard.jpg",
          img: "images/routedashboard.jpg",
          highlight: true,
        },
        // {
        //   id: "Arion",
        //   name: "Arion",
        //   subtitle: "Data Visualization",
        //   description: "Vessels dashboard",
        //   links: [
        //     {
        //       url: "https://app.arion.seamlesshorizons.com/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [],
        //   labels: [
        //     "React",
        //     "D3.js",
        //     "Rechart",
        //     "Mapbox",
        //     "Node.js",
        //     "Deck.gl",
        //     "Harp.gl",
        //     "React Query"
        //   ],
        //   imgSmall: "images/arion.jpg",
        //   img: "images/arion.jpg",
        //   highlight: true,
        // },
        {
          id: "Sudan SCE Explorer",
          name: "Sudan SCE Explorer",
          subtitle: "Data Visualization",
          description:
            "An interactive data visualization of Sudanese SCEs mapping state-controlled enterprises to empower future investigations",
          links: [
            {
              url: "https://sudansce.c4ads.org/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Primefaces", "DataViz", "Cytoscape", "Node.js"],
          imgSmall: "images/soe.jpg",
          img: "images/soe.jpg",
          highlight: true,
        },
        {
          id: "Carbon Capture Visualization",
          name: "Carbon Capture Visualization",
          subtitle: "Carbon Capture Visualization",
          description: "",
          links: [
            {
              url: "https://groovejones-exxonmobil-carboncapture.vercel.app/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Three.js", "R3F", "React-spring"],
          imgSmall: "images/carbon.jpg",
          img: "images/carbon.jpg",
          highlight: true,
        },
        // {
        //   id: "Webaverse",
        //   name: "Webaverse",
        //   subtitle: "MMO universe of 3D apps on the web",
        //   description:
        //     "An open source web3 metaverse game engine that anyone can host. Easy to use, runs in the browser and utilizes open tools and standards like three.js and Node.",
        //   links: [
        //     {
        //       url: "https://webaverse.com/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [
        //     {
        //       url: "https://github.com/webaverse/",
        //       name: "Open the project",
        //       icon: "fab fa-github",
        //     },
        //   ],
        //   labels: ["React", "Three.js", "Shader", "Metaverse"],
        //   imgSmall: "images/webaverse.gif",
        //   img: "images/webaverse.gif",
        //   highlight: true,
        // },
        {
          id: "3D avatar studio",
          name: "3D avatar studio",
          subtitle: "3D avatar studio for the open metaverse",
          description: "",
          links: [
            {
              url: "https://character-studio.vercel.app/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Three.js", "React-spring", "Web3", "Vite"],
          imgSmall: "images/charactercreator.jpg",
          img: "images/charactercreator.jpg",
          highlight: true,
        },
        // {
        //   id: "Dungeon generator",
        //   name: "Dungeon generator",
        //   subtitle:
        //     "Dungeon procedural generator with React, Three.js, and PixiJS",
        //   description: "",
        //   links: [
        //     {
        //       url: "https://tilemap-generator.vercel.app/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [
        //     {
        //       url: "https://github.com/belopot/tilemap-generator",
        //       name: "Open the project",
        //       icon: "fab fa-github",
        //     },
        //   ],
        //   labels: ["React", "Three.js", "PixiJS"],
        //   imgSmall: "images/dungeon.jpg",
        //   img: "images/dungeon.jpg",
        //   highlight: true,
        // },
        {
          id: "Edenica",
          name: "Edenica",
          subtitle: "3D website for apartment sellers",
          description: "Built the elegant 3D website with React and R3F.",
          links: [
            {
              url: "https://vmistudio-edenica.vercel.app/location",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: [
            "React",
            "Three.js",
            "Framer",
            "React-spring",
            "GLSL",
          ],
          imgSmall: "images/edenica.jpg",
          img: "images/edenica.jpg",
          highlight: true,
        },
        {
          id: "Willow Walk",
          name: "Willow Walk",
          subtitle: "3D website for apartment sellers",
          description: "In collaboration with Peabody, one of the UK's oldest and largest housing associations, we have developed an innovative 3D web application designed to revolutionize the home-buying experience. This application specifically targets the sale of new apartments, providing potential buyers with an immersive and interactive way to explore and customize their future homes.",
          links: [
            {
              url: "https://vmistudio-willowwalk.vercel.app/finder",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Three.js", "Framer", "React-spring", "UI Animation"],
          imgSmall: "images/willowwalk.jpg",
          img: "images/willowwalk.jpg",
          highlight: true,
        },
        {
          id: "Park151",
          name: "Park151",
          subtitle: "3D website for apartment sellers",
          description: "Built the elegant 3D website with React and R3F.",
          links: [
            {
              url: "https://www.park151.app/amenities",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Three.js", "R3F", "Framer", "React-spring"],
          imgSmall: "images/park151.jpg",
          img: "images/park151.jpg",
          highlight: true,
        },
        {
          id: "Coronation Square",
          name: "Coronation Square",
          subtitle: "3D Website for real estate company",
          description: "Built the elegant 3D website with React and R3F.",
          links: [
            {
              url: "https://www.coronationsquare.london/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Three.js", "Framer", "React-spring"],
          imgSmall: "images/coronation.jpg",
          img: "images/coronation.jpg",
          highlight: true,
        },
        {
          id: "New Mansion Square",
          name: "New Mansion Square",
          subtitle: "3D Website for real estate company",
          description: "Built the elegant 3D website with React and R3F.",
          links: [
            {
              url: "http://www.newmansionsquare.london/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: [
            "React",
            "Three.js",
            "Framer",
            "React-spring",
            "Mapbox",
          ],
          imgSmall: "images/nmsq.jpg",
          img: "images/nmsq.jpg",
          highlight: true,
        },
        // {
        //   id: "Tiguan",
        //   name: "Tiguan",
        //   subtitle: "Car Viewer",
        //   description: "Built shaders for realistic render pipeline with GLSL.",
        //   links: [
        //     {
        //       url: "https://showroom3d.app/scene/tiguan/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [],
        //   labels: ["Three.js", "GLSL"],
        //   imgSmall: "images/tiguan.jpg",
        //   img: "images/tiguan.jpg",
        //   highlight: true,
        // },
        {
          id: "Carport",
          name: "Carport",
          subtitle: "Online Carport Configurator",
          description: "Built Carport configurator with Three.js and React.",
          links: [
            {
              url: "https://designer.carport1.com/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
            {
              url: "https://vimeo.com/558087425",
              name: "Watch the demo",
              icon: "fas fa-video",
            },
          ],
          secondaryLinks: [],
          labels: ["Python", "Django", "Redis", "PostgreSQL", "Three.js", "React", "Redux", "Blender"],
          imgSmall: "images/carport.jpg",
          img: "images/carport.jpg",
          highlight: true,
        },
        // {
        //   id: "IFC Tool",
        //   name: "IFC Tool",
        //   subtitle: "Web-based IFC Tool with powerful drawing tools",
        //   description:
        //     "IFC Editor with powerful drawing tools. It provides convenient face-to-face, optimization tools, and practical shortcuts for users. Simulation of multiple axes for the model during the development process and provides an efficient manipulation of the building parts. Provides a simple and powerful simulation that can be used by non-professional users. It's a 2d & 3d drawing tool that can expect huge success with simple operations like Paint or SketchUp. It supports powerful tools to convert your 2d environment to a 3d environment. Compatible with the 3D BIM Viewer tool",
        //   links: [
        //     {
        //       url: "https://vimeo.com/558687304",
        //       name: "Watch the old version",
        //       icon: "fas fa-video",
        //     },
        //     {
        //       url: "https://vimeo.com/558684710",
        //       name: "Watch the new version",
        //       icon: "fas fa-video",
        //     },
        //   ],
        //   secondaryLinks: [],
        //   labels: ["XeoGL", "Firebase"],
        //   imgSmall:
        //     "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/140fea5d-8770-4a5e-83ba-48656620453d.gif?ClientID=vimeo-core-prod&Date=1622748446&Signature=ac9840422cd3eaa809de4f63ac72b5c841a023d8",
        //   img: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/140fea5d-8770-4a5e-83ba-48656620453d.gif?ClientID=vimeo-core-prod&Date=1622748446&Signature=ac9840422cd3eaa809de4f63ac72b5c841a023d8",
        //   highlight: true,
        // },
        // {
        //   id: "Shirt configurator",
        //   name: "Shirt configurator",
        //   subtitle: "Online 3D Shirt Configurator",
        //   description: "Built 3D shirt configurator with WebGL.",
        //   links: [
        //     {
        //       url: "https://cdn.threadmarklive.com/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [],
        //   labels: ["WebGL", "Three.js", "Angular"],
        //   imgSmall:
        //     "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/76/1160132/6e13dd25d0a14ae7e57c47e798f9352c-original.JPG",
        //   img: "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/76/1160132/6e13dd25d0a14ae7e57c47e798f9352c-original.JPG",
        //   highlight: true,
        // },
        {
          id: "EarlyVision",
          name: "EarlyVision",
          subtitle: "Online 3D product Configurator",
          description: "Built 3D product Configurator with React and Three.js",
          links: [
            {
              url: "https://earlyvision-configurator.vercel.app/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["React", "Zendesk", "Firebase", "Three.js"],
          imgSmall: "images/earlyvision.jpg",
          img: "images/earlyvision.jpg",
          highlight: true,
        },
        {
          id: "Panasonic CES 2021",
          name: "Panasonic CES 2021",
          subtitle: "",
          description: "Built the Panasonic CES 2021 with Three.js and Next.js",
          links: [
            {
              url: "https://panasonic-timeline.vercel.app/",
              name: "Open the timeline",
              icon: "fas fa-external-link-alt",
            },
            {
              url: "https://panasonic-product-viewer.vercel.app/",
              name: "Open the model viewer",
              icon: "fas fa-external-link-alt",
            },
            {
              url: "https://carousel.integrator.net/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [
            {
              url: "https://vimeo.com/558682034",
              name: "Watch a 3D carousel",
              icon: "fas fa-video",
            },
            {
              url: "https://vimeo.com/558678749",
              name: "Watch a car interior",
              icon: "fas fa-video",
            },
            {
              url: "https://vimeo.com/558683280",
              name: "Watch a timeline",
              icon: "fas fa-video",
            },
          ],
          labels: ["React", "Next.js", "Three.js", "3dsMax", "GLSL"],
          imgSmall:
            "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/519eac8d-b139-490f-bf3e-a84dad570030.gif?ClientID=vimeo-core-prod&Date=1622752908&Signature=c75327eb093798bffdcf74df6574cbcafca383d0",
          img: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/519eac8d-b139-490f-bf3e-a84dad570030.gif?ClientID=vimeo-core-prod&Date=1622752908&Signature=c75327eb093798bffdcf74df6574cbcafca383d0",
          highlight: true,
        },
        // {
        //   id: "Kozikaza Planner",
        //   name: "Kozikaza Planner",
        //   subtitle: "Online 2D/3D Home Planner",
        //   description:
        //     "Built the powerful engine that turn the 2D image floor plan into a 3D floor plan. Instead of drawing out the floor plan from scratch, which is time-consuming, see the floor plan software that automatically converts 2D floor plan images to 3D floor plans that you can edit.",
        //   links: [
        //     {
        //       url: "https://www.kozikaza.com/en/kazaplan/new/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [],
        //   labels: ["WebGL", "Babylon.js", "Angular"],
        //   imgSmall:
        //     "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/fb/1126032/9e0ca4dc6e6e232bd52cdd27086a69c4-original.JPG",
        //   img: "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/fb/1126032/9e0ca4dc6e6e232bd52cdd27086a69c4-original.JPG",
        //   highlight: true,
        // },
        {
          id: "Vertical farm configurator",
          name: "Vertical farm configurator",
          subtitle: "Online 3D Vertical Farm Configurator for Logiqs B.V.",
          description:
            "Built 3D automatic vertical farm configurator with Babylon.js.",
          links: [
            {
              url: "https://greencube.logiqs3d.nl/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: ["Babylon.js", "JavaScript", "Html", "CSS"],
          imgSmall:
            "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/8f/1123423/cd444cb21d38527189f331b74f7c6a16-original.JPG",
          img: "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/8f/1123423/cd444cb21d38527189f331b74f7c6a16-original.JPG",
          highlight: true,
        },
        {
          id: "Vehicle Configurator",
          name: "Vehicle Configurator",
          subtitle: "Online 3D Vehicle Configurator",
          description: "Frontend, admin dashboard and backend for CMS",
          links: [
            {
              url: "https://vimeo.com/558104676",
              name: "Watch the demo",
              icon: "fas fa-video",
            },
          ],
          secondaryLinks: [],
          labels: ["WebGL", "React", "Node.js", "MongoDB", "CMS"],
          imgSmall: "images/vehicleconf.jpg",
          img: "images/vehicleconf.jpg",
          highlight: true,
        },
        // {
        //   id: "Animascode",
        //   name: "Animascode",
        //   subtitle: "3D Product Configurator",
        //   description: "Built web 3D product configurator with Babylon.js.",
        //   links: [
        //     {
        //       url: "https://animascode.bespokefactory.com/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [],
        //   labels: ["Babylon.js", "React", "ASP.NET", "Azure", "Cinema4D"],
        //   imgSmall: "images/animascode.jpg",
        //   img: "images/animascode.jpg",
        //   highlight: true,
        // },
        // {
        //   id: "Terrida",
        //   name: "Terrida",
        //   subtitle: "Online Bag Configurator",
        //   description: "Built bag configurator with WebGL.",
        //   links: [
        //     {
        //       url: "https://configurator.terrida.com/configurator/atleta/1310_borsone_sport/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [],
        //   labels: ["WebGL", "Babylon.js", "GSAP", "Blender"],
        //   imgSmall:
        //     "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/eb/1160325/305c484723d11694023584de5ba7adce-original.JPG",
        //   img: "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/eb/1160325/305c484723d11694023584de5ba7adce-original.JPG",
        //   highlight: true,
        // },
        {
          id: "3DL",
          name: "3DL",
          subtitle: "Built the training 3D Web App with Three.js and Node.js",
          description:
            "3DL is a visual and interactive teaching and learning platform with content that is suitable for students across different school grades. It helps teachers to engage students better. Students are able to EXPLORE, EXAMINE and EXPLAIN using 3D Models, Videos, Animations and a range of other activities.",
          links: [
            {
              url: "https://vimeo.com/558690242",
              name: "Watch the video",
              icon: "fas fa-video",
            },
          ],
          secondaryLinks: [
            {
              url: "https://www.microsoft.com/en-us/p/3dl/9nbmlv7xhj3n?rtc=1&activetab=pivot%3Aoverviewtab/",
              name: "Learn more on the app's site (Unity3D App)",
              icon: "fab fa-windows",
            },
          ],
          labels: ["Three.js", "Node.js", "MongoDB", "CMS", "Unity3D"],
          imgSmall: "images/3dl.jpg",
          img: "images/3dl.jpg",
          highlight: true,
        },
        {
          id: "crystal-3d",
          name: "crystal-3d",
          subtitle: "Interactive 3D website",
          description:
            "Built the material of crystals with GLSL and the animation with Greensock",
          links: [
            {
              url: "https://crystal-3d.web.app/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
            {
              url: "https://vimeo.com/559314952",
              name: "Watch the demo",
              icon: "fas fa-video",
            },
          ],
          secondaryLinks: [],
          labels: ["WebGL", "Three.js", "GLSL", "GSAP", "3dsMax"],
          imgSmall:
            "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/74fc4526-7eae-4f0c-8ccc-dee8d7c17c28.gif?ClientID=vimeo-core-prod&Date=1622877790&Signature=94d92ae50726ae48a2fe6361fcecb5dec798a148",
          img: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/74fc4526-7eae-4f0c-8ccc-dee8d7c17c28.gif?ClientID=vimeo-core-prod&Date=1622877790&Signature=94d92ae50726ae48a2fe6361fcecb5dec798a148",
          highlight: true,
        },
        {
          id: "3D showcase",
          name: "3D showcase",
          subtitle: "Data 3D Visualization",
          description:
            "A WebGL piece done for a client, this 3D photo directory was used as a showcase piece at a event in Netherlands, where this was shown on a gorgeous event screen the width of a banquet hall.",
          links: [
            {
              url: "https://belopot.github.io/member-showcase/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [
            {
              url: "https://github.com/belopot/member-showcase/",
              name: "Open the project",
              icon: "fab fa-github",
            },
          ],
          labels: ["DataViz", "WebGL", "Three.js", "GSAP"],
          imgSmall:
            "https://github.com/belopot/member-showcase/raw/master/screenshots/0.gif",
          img: "https://github.com/belopot/member-showcase/raw/master/screenshots/0.gif",
          highlight: true,
        },
        // {
        //   id: "Bubble Animation",
        //   name: "Bubble Animation",
        //   subtitle: "Bubble Animation",
        //   description: "Built bubble animation with GLSL and Three.js.",
        //   links: [],
        //   secondaryLinks: [
        //     {
        //       url: "https://github.com/belopot/bubble-animation-shader/",
        //       name: "Open the project",
        //       icon: "fab fa-github",
        //     },
        //   ],
        //   labels: ["GLSL", "Shader"],
        //   imgSmall:
        //     "https://github.com/belopot/bubble-animation-shader/raw/master/screenshots/0.gif",
        //   img: "https://github.com/belopot/bubble-animation-shader/raw/master/screenshots/0.gif",
        //   highlight: false,
        // },
        // {
        //   id: "Path Animation",
        //   name: "Path Animation",
        //   subtitle: "SVG Path Animation in Three.js",
        //   description: "Built svg path animation with GLSL and Three.js.",
        //   links: [
        //     {
        //       url: "https://belopot.github.io/svg-path-animation/dist/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt",
        //     },
        //   ],
        //   secondaryLinks: [
        //     {
        //       url: "https://github.com/belopot/svg-path-animation/",
        //       name: "Open the project",
        //       icon: "fab fa-github",
        //     },
        //   ],
        //   labels: ["GLSL", "Shader"],
        //   imgSmall:
        //     "https://github.com/belopot/svg-path-animation/raw/master/screenshots/0.gif",
        //   img: "https://github.com/belopot/svg-path-animation/raw/master/screenshots/0.gif",
        //   highlight: false,
        // },
        // {
        //   id: "theyearofgreta",
        //   name: "theyearofgreta",
        //   subtitle: "Personal website for Greta Thunberg",
        //   description: "Built interactive 3D website with Three.js and GSAP.",
        //   links: [
        //     {
        //       url: "https://theyearofgreta.com/",
        //       name: "Open the website",
        //       icon: "fas fa-external-link-alt"
        //     }
        //   ],
        //   secondaryLinks: [],
        //   labels: ["Three.js", "GSAP", "Webpack", "React"],
        //   imgSmall:
        //     "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/e1/1160252/fd48fa6d402754326f9b4816bccbbdb2-original.JPG",
        //   img: "https://s3.amazonaws.com/poly-screenshots.angel.co/Project/e1/1160252/fd48fa6d402754326f9b4816bccbbdb2-original.JPG",
        //   highlight: true
        // },
        // {
        //   id: "Weapon Viewer",
        //   name: "Weapon Viewer",
        //   subtitle: "Web 3D Weapon Viewer",
        //   description: "Built web 3D weapon viewer with Three.js and ReactJS.",
        //   links: [],
        //   secondaryLinks: [
        //     {
        //       url: "https://github.com/belopot/weapon-viewer/",
        //       name: "Open the project",
        //       icon: "fab fa-github",
        //     },
        //   ],
        //   labels: ["Three.js", "React"],
        //   imgSmall:
        //     "https://github.com/belopot/weapon-viewer/raw/master/public/assets/screenshots/akm.JPG",
        //   img:
        //     "https://github.com/belopot/weapon-viewer/raw/master/public/assets/screenshots/akm.JPG",
        //   highlight: false,
        // },
        {
          id: "3D Product Configurator",
          name: "3D Product Configurator",
          subtitle: "3D Product Configurator",
          description:
            "Built web 3D Product Configurator with Three.js and ReactJS.",
          links: [
            {
              url: "https://3d-product-configurator.vercel.app/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [
            {
              url: "https://github.com/belopot/3d-product-configurator",
              name: "Open the project",
              icon: "fab fa-github",
            },
          ],
          labels: ["Three.js", "React", "Redux"],
          imgSmall:
            "https://github.com/belopot/3d-product-configurator/raw/master/screenshots/image1.jpg",
          img: "https://github.com/belopot/3d-product-configurator/raw/master/screenshots/image1.jpg",
          highlight: true,
        },

        {
          id: "Interactive Brain",
          name: "Interactive Brain",
          subtitle: "Simulate the signals of brain",
          description: "Built interactive 3D brain with WebGL.",
          links: [
            {
              url: "https://belopot.github.io/interactive-brain/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [
            {
              url: "https://github.com/belopot/interactive-brain/",
              name: "Open the project",
              icon: "fab fa-github",
            },
          ],
          labels: ["WebGL", "Three.js", "GLSL", "GSAP", "Shader"],
          imgSmall:
            "https://github.com/belopot/interactive-brain/raw/master/screenshots/0.gif",
          img: "https://github.com/belopot/interactive-brain/raw/master/screenshots/0.gif",
          highlight: true,
        },
        {
          id: "BOSS Startup Science",
          name: "BOSS Startup Science",
          subtitle:
            "A SaaS platform that helps founders succeed through education and connections",
          description:
            "A SaaS platform that helps founders succeed through education and connections",
          links: [
            {
              url: "https://www.startupscience.io/",
              name: "Open the website",
              icon: "fas fa-external-link-alt",
            },
          ],
          secondaryLinks: [],
          labels: [
            "HTML",
            "CSS",
            "JavaScript",
            "PHP",
            "Tailwind",
            "Vue.js",
            "Htmx",
            "Hyperscript",
            "Laravel",
            "MySQL",
            "AWS",
            "Docker",
            "Nginx",
            "Redis",
          ],
          imgSmall:
            "images/startupscience.jpg",
          img: "images/startupscience.jpg",
          highlight: true,
        },
      ]),
    ),
}
