// eslint-disable-next-line
export default {
  fetchSocials: () =>
    new Promise((resolve, reject) =>
      resolve([
        {
          name: "email",
          text: "jack.toptal@gmail.com",
          icon: null,
          url: "mailto:jack.toptal@gmail.com",
        },
        {
          name: "github",
          text: "",
          icon: "fab fa-github",
          url: "https://github.com/belopot/",
        },
        // {
        //   name: "twitter",
        //   text: "",
        //   icon: "fab fa-twitter",
        //   url: "https://twitter.com/jacktoptal/",
        // },
        {
          name: "telegram",
          text: "",
          icon: "fab fa-telegram",
          url: "https://t.me/belopot/",
        },
        {
          name: "discord",
          text: "",
          icon: "fab fa-discord",
          url: "https://discordapp.com/users/belopot#7982/",
        },
        // {
        //   name: "angellist",
        //   text: "",
        //   icon: "fab fa-angellist",
        //   url: "https://angel.co/u/belopot/"
        // },
        // {
        //   name: "sketchfab",
        //   text: "",
        //   icon: "fa fa-cube",
        //   url: "https://sketchfab.com/adros0214"
        // }
      ]),
    ),
}
