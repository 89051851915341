import React from "react"

import profilePic from "../../../assets/images/profile.jpg"

import "../../_common/common.css"
import "./about.css"

import ReactGA from "react-ga"
import AvatarCanvas from "../../AvatarCanvas"

const About = () => {
  const onLinkClick = () => {
    ReactGA.event({
      category: "User",
      action: "Click on CV",
    })
  }

  return (
    <div className="home-section container-about">
      {/* <img
        className="profile-picture-about"
        alt="profilepic"
        src={profilePic}
      /> */}
      <AvatarCanvas />
      <div className="about-text">
        <div className="about-text-main">Hi, I'm Jack</div>
        <br />
        <span className="about-text-subtitle">
        Accomplished Frontend Engineer with more than 10 years of experience creating visually stunning and user-friendly SaaS applications that delight users and drive business growth.
        <br />
        My expertise lies in web application development for 3D and data visualization
        </span>
        <br />
        <br />
        If you want to know more:{" "}
        <a
          onClick={onLinkClick}
          href="https://docs.google.com/document/d/1FLqa-GxS5szk-vGfk19SEE8ORJQwYfzvEXgLqOFhXL0/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="about-cv">HERE is my Resume.</span>
        </a>
        <br />
        <br />
        Keep scrolling to see some of the projects I've worked on.
      </div>
    </div>
  )
}

export default About
